import React, { useState, useEffect } from 'react';
import bannerVideoDesktop from './topBannerVideoDesktop.mp4'; // Desktop video file path
import bannerVideoMobile from './topBannerVideoMobile.mp4'; // Mobile video file path
import './TopBanner.css';
import { Button } from '@mui/base';

const TopBanner = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState('100%'); // Set default width to 100%
  const [subtexts, setSubtexts] = useState(['Web Design & Development', 'Brand Development', 'Digital Marketing', 'Photography']);
  const [currentSubtextIndex, setCurrentSubtextIndex] = useState(0);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const video = document.getElementById('topBannerVideo');

    const setBannerHeight = () => {
      const videoHeight = video.videoHeight;
      setVideoHeight(videoHeight);
      setIsLoaded(true);
    };

    video.addEventListener('loadedmetadata', setBannerHeight);
    // Call setBannerHeight immediately in case the metadata is already loaded
    if (video.readyState >= 1) {
      setBannerHeight();
    }

    // Delay the appearance of text and start of text change timer
    const textTimeout = setTimeout(() => {
      setShowText(true);
      const textChangeTimer = setInterval(() => {
        setCurrentSubtextIndex(prevIndex => (prevIndex + 1) % subtexts.length); // Rotate through subtexts
      }, 4350); // Change text every 4.09 seconds
      return () => clearInterval(textChangeTimer); // Clear timer on component unmount
    }, 1100); // Delay text appearance by 1100 milliseconds (1.1 seconds)

    return () => {
      video.removeEventListener('loadedmetadata', setBannerHeight);
      clearTimeout(textTimeout);
    };
  }, []);

  useEffect(() => {
    // Adjust video width based on isMobile state
    setVideoWidth(isMobile ? '100%' : 'auto');
  }, [isMobile]);

  return (
    <div className={`top-banner ${isLoaded ? 'fade-in-active' : ''}`} style={{ height: videoHeight }}>
      <video
        src={isMobile ? bannerVideoMobile : bannerVideoDesktop}
        autoPlay
        loop
        muted
        playsInline
        id="topBannerVideo"
        className={`banner-video ${isLoaded ? 'fade-in-video' : ''}`}
        style={{ width: videoWidth }} // Set video width dynamically
      />
      <div className="text-overlay">
        <h1>Where Creativity Meets Innovation.</h1>
        {showText && <p className="sub-text">{subtexts[currentSubtextIndex]}</p>}
        <Button className="learn-more">Learn More</Button>
      </div>
    </div>
  );
};

export default TopBanner;
