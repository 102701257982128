import TopBanner from '../../components/TopBanner/TopBanner';
import AboutText from '../../components/AboutText/AboutText';
import NavBar from '../../components/NavBar/NavBar';
import SplitBox from '../../components/SplitBox/SplitBox';
import ContactForm from '../../components/ContactForm/ContactForm';
import VideoWithText from '../../components/VideoWithText/VideoWithText';
import SEOBanner from '../../components/SEOBanner/SEOBanner';



function Homepage () {
  return (
    <div className="homePage">
      <TopBanner />
      <AboutText />
      <div className="space"></div>
      <VideoWithText
        videoSrc="https://i.imgur.com/uAaAfbx.mp4"
        text1="DESIGN"
        text2="Bringing your brand's vision to life online."
        text3="Watch your dreams take shape with vivid clarity, led by our expert web design team."
        buttonText="Learn More"
      />
         <VideoWithText
        videoSrc="https://i.imgur.com/H8cQSfK.mp4"
        text1="BRAND DEVELOPMENT"
        text2="Crafting clear, compelling messages that resonate."
        text3="Shape your brand's story and forge meaningful connections with your most valued audiences."
        buttonText="Learn More"
      />
         <VideoWithText
        videoSrc="https://i.imgur.com/lAAoobQ.mp4"
        text1="DEVELOPMENT"
        text2="Transforming ideas into seamless digital experiences."
        text3="Empower your vision with our expert development team, translating concepts into intuitive online solutions."
        buttonText="Learn More"
      />
         {/* <VideoWithText
        videoSrc="https://i.imgur.com/uAaAfbx.mp4"
        text1="DESIGN"
        text2="Bringing your brand's vision to life online."
        text3="Watch your dreams take shape with vivid clarity, led by our expert web design team."
        buttonText="Learn More"
      /> */}
      {/* <SplitBox /> */}
      <SEOBanner />
      <div className="empty-div"></div>
    </div>
  );
}

export default Homepage;