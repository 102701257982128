import React from 'react';
import './VideoWithText.css'; // Assuming you have a CSS file for styling
import { Button } from '@mui/base';

const VideoWithText = ({ videoSrc, text1, text2, text3, buttonText }) => {
  return (
    <div className="video-with-text">
      <div className="video-container-f1">
        <video src={videoSrc} autoPlay loop muted playsInline />
      </div>
      <div className="text-container">
        <p className="upper-text">{text1}</p>
        <p className="middle-text">{text2}</p>
        <p className="lower-text">{text3}</p>
        <Button>{buttonText}</Button>
      </div>
    </div>
  );
};

export default VideoWithText;