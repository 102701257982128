import React, { useEffect, useRef } from 'react';
import './AboutText.css';

const AboutText = () => {
  const centeredContentRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when 50% of the component is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('centered-content-show'); // Add the class to trigger animation
          observer.unobserve(entry.target); // Stop observing once animation is triggered
        }
      });
    }, options);

    if (centeredContentRef.current) {
      observer.observe(centeredContentRef.current);
    }

    return () => {
      observer.disconnect(); // Cleanup
    };
  }, []);

  return (
    <div className="about-text" id="about-section">
      <div className="centered-content" ref={centeredContentRef}>
        <p className="smaller-about">Unleash the Power of Creativity</p>
        <p className="bigger-about">Elevate your online presence with our comprehensive agency services.</p>
        <button className="book-now-button"><a className="book-button" href="#contact">Book Now</a></button>
      </div>
    </div>
  );
};

export default AboutText;
