import React, { useState } from 'react';
import './NavBar.css';
import Logo from './logo.png'; // Import your logo image

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="header-container">
      <div className="header-wrapper">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="menu">
          <div className="hamburger" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={`navbar-items ${isMenuOpen ? 'open' : ''}`}>
            <li><a className="nav-item" href="/">Home</a></li>
            <li><a className="nav-item" href="/portfolio">Projects</a></li>
            {/* <li><a className="nav-item" href="/results">Results</a></li> */}
            <li><a className="nav-item" href="/#about-section">About</a></li>
            <li><a className="nav-item" href="/#contact">Contact</a></li>
          </ul>
        </div>
        <div className="contact-container">
          <button>
            <a className="callNow" href="tel:+8182092305">Call Now</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
