import React, { useState, useEffect } from 'react';
import './SEOBanner.css';
import { Button } from '@mui/base';
import SEOImage from './seo-monitor.png'; // Import your SEO image

const SEOBanner = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const banner = document.querySelector('.SEOBanner');
            if (banner) {
                const bannerTop = banner.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;
                if (bannerTop < windowHeight * 0.8) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="SEOBanner">
            <div className="seo-text">
                <h2 className="seo-heading">SEO</h2>
                <p className="seo-banner-text">Secure prime visibility and establish unwavering authority and credibility with our proven optimization strategies, consistently placing you at the forefront of search results.</p>
                <Button className="seoButton">Learn More</Button>
            </div>
            <div className={`image ${isVisible ? 'visible' : ''}`}>
                <img src={SEOImage} alt="SEO Results" />
            </div>
        </div>
    );
};

export default SEOBanner;
